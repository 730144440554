<template>
    <div id="app">
        <div class="header">
            <div class="title">
                <h1>Perma CHAT!</h1>
            </div>
            <div class="select-network">
            </div>
            <div v-if="mainLoading" class="account">

                <button v-on:click="connectMetamask">Connect Metamask</button>
            </div>

            <div v-else class="account">
                {{ selectedNetworkName }} <br/>
                Logged in as <span v-if="user.nickname !== 'Anonymous'">{{ user.nickname }}</span><span
                    v-else>{{ user.address | shortenAddress }}</span>
                <div class="balance" v-if="selectedToken">

                    Balance: <span v-if="retreivingBalance">Loading...</span>
                    <span v-else>{{ currentBalance | shortenBalance }} {{ selectedToken.symbol }}</span>
                    <br/>
                    Msg fee: <span v-if="retreivingFee">Loading...</span>
                    <span v-else>{{ currentFee | shortenBalance }} {{ selectedToken.symbol }}</span>

                </div>
            </div>
        </div>


        <div class="chat" ref="chat">
            <div v-for="message in messages" :key="message.id">
                <div class="messages mine last"
                     v-if="message.user.sender.toLowerCase() === user.address.toLowerCase()">

                    <div class="message">
                        <div class="sender" v-if="message.user.nickname !== 'Anonymous'">{{
                            message.user.nickname
                            }}
                        </div>
                        <div class="sender" v-else>{{ message.user.sender | shortenAddress }}</div>
                        {{ message.content }}
                    </div>
                </div>
                <div class="messages yours" v-else>

                    <div class="message">
                        <div class="sender" v-if="message.user.nickname !== 'Anonymous'">{{
                            message.user.nickname
                            }}
                        </div>
                        <div class="sender" v-else>{{ message.user.sender | shortenAddress }}</div>
                        {{ message.content }}
                    </div>
                </div>
            </div>
            <div ref="bottomChat"></div>
            <br/>

        </div>

        <div class="footer">
            <div class="imessage-input">
                <input type="text"
                       v-model="newMessage"
                       placeholder="your Perma message..."
                />
                <button type="submit"
                        class="change-button"
                        :disabled="newMessage.length === 0"
                        v-on:click="submitMessage">Send
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {Contract, Web3Provider, Provider} from "zksync-web3";
import Web3 from 'web3';
import {ethers} from "ethers";
import Vue from "vue";

// eslint-disable-next-line 
// linea 0xc2897beF64BBa7Ad38C8dCCCEA4e6f879425b38B         
let PERMACHAT_CONTRACT_ADDRESS = "0xC0Af1833243D347b96F45285D3fCC66966EF1A1A"; // TODO: Add smart contract address
// eslint-disable-next-line
const PERMACHAT_CONTRACT_ABI = require("./abi.json"); // TODO: Complete and import the ABI

const allowedTokens = require("./eth.json");
const supportedNetworks = require("./networks.json");

export default {
    name: "App",
    watch: {
        newMessage() {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.updateFee();
            }, 500);
        }
    },
    data() {
        return {
            chatHeight: 'calc(100vh - 100px)',
            user: {
                address: "Unknown",
                nickname: "Unknown"
            },
            newMessage: "",
            messages: [{
                id: 0,
                user: {
                    sender: "0xc2897beF64BBa7Ad38C8dCCCEA4e6f879425b38B",
                    nickname: "Admin"
                },
                content: "Welcome to Perma CHAT!"
            }, {
                id: 1,
                user: {
                    sender: "0xc2897beF64BBa7Ad38C8dCCCEA4e6f879425b38B",
                    nickname: "Admin"
                },
                content: "We are on Base mainnet, zkSync Era testnet/mainnet, Scroll testnet/mainnet, LightLink mainnet and Linea testnet."
            }, {
                id: 2,
                user: {
                    sender: "0xc2897beF64BBa7Ad38C8dCCCEA4e6f879425b38B",
                    nickname: "Admin"
                },
                content: "Connect your Metamask wallet to start chatting! Pump up the tx count 🪂"
            }],
            selectedNetwork: "-1",
            selectedNetworkName: "",
            supportedNetworks: supportedNetworks,
            tokens: allowedTokens,
            selectedToken: null,
            selectedTokenAddress: allowedTokens[0].address,
            mainLoading: true,
            provider: null,
            signer: null,
            contract: null,
            canSubmit: true,
            // 0 stands for no status, i.e no tx has been sent
            // 1 stands for tx is beeing submitted to the operator
            // 2 stands for tx awaiting commit
            // 3 stands for updating the balance and message on the page
            txStatus: 0,
            retreivingFee: false,
            retreivingBalance: false,

            currentBalance: "",
            currentFee: "",
        };
    },
    methods: {
        initializeProviderAndSigner() {
            if (+window.ethereum.networkVersion == "280") {
                this.provider = new Provider('https://testnet.era.zksync.dev');
                PERMACHAT_CONTRACT_ADDRESS = "0x0f7Ffca7d05734121d900028E3A14A42c4e59364";
                this.signer = (new Web3Provider(window.ethereum)).getSigner();
                this.selectedNetworkName = "zkSync Testnet";
            } else if (+window.ethereum.networkVersion == "324") {
                this.provider = new Provider('https://zksync2-mainnet.zksync.io');
                PERMACHAT_CONTRACT_ADDRESS = "0x426dd6936b3889A1874d4Bf7980786B8a3E320BF";
                this.signer = (new Web3Provider(window.ethereum)).getSigner();
                this.selectedNetworkName = "zkSync Mainnet";
            } else if (+window.ethereum.networkVersion == "59140") {
                const web3 = new Web3(window.ethereum);
                this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
                // this.provider = new ethers.providers.JsonRpcProvider('https://rpc.goerli.linea.build');
                PERMACHAT_CONTRACT_ADDRESS = "0xdD27a2DD2aF0EDac607f0A3BcF67Cb92ad929E26";
                this.signer = this.provider.getSigner();
                this.selectedNetworkName = "Linea Testnet";
            } else if (+window.ethereum.networkVersion == "534353") {
                const web3 = new Web3(window.ethereum);
                this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
                //this.provider = new ethers.providers.JsonRpcProvider('https://alpha-rpc.scroll.io/l2');
                PERMACHAT_CONTRACT_ADDRESS = "0x3DB52A32A7c65899bDfD8a820A21fDa85B6eB4CA";
                this.signer = this.provider.getSigner();
                this.selectedNetworkName = "Scroll Testnet";
            } else if (+window.ethereum.networkVersion == "534352") {
                const web3 = new Web3(window.ethereum);
                this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
                //this.provider = new ethers.providers.JsonRpcProvider('https://alpha-rpc.scroll.io/l2');
                PERMACHAT_CONTRACT_ADDRESS = "0xBAea41a05aD6E7Fc1A08646bB1c5da46435dF418";
                this.signer = this.provider.getSigner();
                this.selectedNetworkName = "Scroll Mainnet";
            } else if (+window.ethereum.networkVersion == "8453") {
                const web3 = new Web3(window.ethereum);
                this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
                //this.provider = new ethers.providers.JsonRpcProvider('https://base.blockpi.network/v1/rpc/public');
                PERMACHAT_CONTRACT_ADDRESS = "0xD27387B9e4032B25B74175EA4eB675Ea62aAc0CC";
                this.signer = this.provider.getSigner();
                this.selectedNetworkName = "Base Mainnet";
            }else if (+window.ethereum.networkVersion == "1890") {
                const web3 = new Web3(window.ethereum);
                this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
                //this.provider = new ethers.providers.JsonRpcProvider('https://base.blockpi.network/v1/rpc/public');
                PERMACHAT_CONTRACT_ADDRESS = "0x1b0f4e260Fe54970951Cec07b4D41d548C278E66";
                this.signer = this.provider.getSigner();
                this.selectedNetworkName = "LightLink Mainnet";
            }

            // Note that we still need to get the Metamask signer

            this.contract = new Contract(
                PERMACHAT_CONTRACT_ADDRESS,
                PERMACHAT_CONTRACT_ABI,
                this.signer
            );
            console.log(this.contract);
        },
        async getUser(address) {
            return await this.contract.getUser(address);
        },
        async getMessages() {
            // TODO: return the current messages
            return await this.contract.getMessages();
        },
        async getFee() {
            // Getting the amount of gas (gas) needed for one transaction
            const messagePriceInUnits = await this.contract.getMessageFee(this.newMessage);
            const options = {
                gasPrice: 1000000000, // 1 gwei
                value: messagePriceInUnits // 0.001 ETH
            };
            const feeInGas = await this.contract.estimateGas.sendMessage(this.newMessage, options);
            const gasPriceInUnits = await this.provider.getGasPrice();
            let totalGas = feeInGas.mul(gasPriceInUnits);

            const totalGasEth = ethers.utils.formatUnits(parseFloat(totalGas) + parseFloat(messagePriceInUnits), this.selectedToken.decimals);
            return totalGasEth;
        },
        async getBalance() {
            // Getting the balance for the signer in the selected token
            const balanceInUnits = await this.signer.getBalance(this.selectedToken.l2Address);
            // To display the number of tokens in the human-readable format, we need to format them,
            // e.g. if balanceInUnits returns 500000000000000000 wei of ETH, we want to display 0.5 ETH the user
            return ethers.utils.formatUnits(balanceInUnits, this.selectedToken.decimals);
        },
        async getOverrides() {

            const messageFeeInUnits = await this.contract.getMessageFee(this.newMessage);

            return {
                value: messageFeeInUnits, // 0.001 ETH
            };
        },

        async submitMessage() {

            if (this.mainLoading) {
                alert(JSON.stringify("Connect to Metamask first! We are on zkSync Era testnet/mainnet, Scroll Alpha testnet and Linea testnet."));
                return;
            }

            this.txStatus = 1;
            try {
                // TODO: Submit the transaction
                const txHandle = await this.contract.sendMessage(this.newMessage, await this.getOverrides());

                this.txStatus = 2;

                // TODO: Wait for transaction compilation
                await txHandle.wait();
                this.txStatus = 3;

                this.messages = await this.getMessages();
                this.newMessage = "";

                this.retreivingFee = true;
                this.retreivingBalance = true;
                // Update balance and fee
                this.currentBalance = await this.getBalance();
                this.currentFee = await this.getFee();
            } catch (e) {
                alert(JSON.stringify(e));
            }

            this.txStatus = 0;
            this.retreivingFee = false;
            this.retreivingBalance = false;

            this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
        },

        updateFee() {
            this.retreivingFee = true;
            this.getFee()
                .then((fee) => {
                    this.currentFee = fee;
                })
                .catch((e) => console.log(e))
                .finally(() => {
                    this.retreivingFee = false;
                });
        },
        updateBalance() {
            this.retreivingBalance = true;
            this.getBalance()
                .then((balance) => {
                    this.currentBalance = balance;
                })
                .catch((e) => console.log(e))
                .finally(() => {
                    this.retreivingBalance = false;
                });
        },
        changeToken() {
            this.retreivingFee = true;
            this.retreivingBalance = true;
            const l1Token = this.tokens.filter(
                (t) => t.address == this.selectedTokenAddress
            )[0];

            this.selectedToken = {
                l1Address: l1Token.address,
                decimals: l1Token.decimals,
                symbol: l1Token.symbol,
            };
            this.updateFee();
            this.updateBalance();

            this.retreivingFee = false;
            this.retreivingBalance = false;
        },
        loadMainScreen() {
            this.initializeProviderAndSigner();

            if (!this.provider || !this.signer) {
                alert("Follow the tutorial to learn how to connect to Metamask!");
                return;
            }

            console.log(window.ethereum.selectedAddress);
            this.getUser(window.ethereum.selectedAddress).then((nickname) => {
                this.user.address = window.ethereum.selectedAddress;
                if (nickname)
                    this.user.nickname = nickname;
            });
            console.log(this.user.address);

            this.getMessages().then((messages) => {
                this.messages = messages;
                this.mainLoading = false;
            });

            console.log(this.mainLoading);

            this.changeToken();
        },
        scrollToElement() {
            const el = this.$refs.bottomChat;

            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        },
        connectMetamask() {
            window.ethereum
                .request({method: "eth_requestAccounts"})
                .then(() => {
                    const supportedNetworks = ["280", "324", "59140", "534353", "8453", "534352", "1890"];
                    if (supportedNetworks.includes(window.ethereum.networkVersion)) {
                        this.loadMainScreen();
                    } else {
                        this.selectedNetworkName = "Unsupported network";
                        alert("Please switch network to Base mainnet, zkSync Era testnet/mainnet, Scroll testnet/mainnet, LightLink mainnet and Linea testnet.! You are on currnelty on chainID " + window.ethereum.networkVersion + " right now.");
                    }
                })
                .catch((e) => console.log(e));
        },
        handleNetworkChange(networkId) {
            // Do something when the network changes
            console.log(`Network changed to ${networkId}`);
            document.location.reload()
        },
    },
    mounted() {
        this.scrollToElement();

        if (window.ethereum) {
            window.ethereum.on('networkChanged', this.handleNetworkChange);
        }
    },
    beforeDestroy() {
        if (window.ethereum) {
            window.ethereum.off('networkChanged', this.handleNetworkChange);
        }
    },
};

Vue.filter('shortenAddress', function (value) {
    if (!value) return ''
    const firstChars = value.substr(0, 3)
    const lastChars = value.substr(-3)
    return `${firstChars}...${lastChars}`
});

Vue.filter('shortenBalance', function (value) {
    if (!value) return ''
    const firstChars = value.substr(0, 6)
    return `${firstChars}`
});
</script>